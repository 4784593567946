<template>
  <div class="login">
    <div class="login-nav">
      <div class="login-nav-main">
        <div class="login-nav-left">
          <div class="">河南省职业教育专业建设在线学习平台</div>
          <div class="yh-lh30">Henan Province vocational education professional construction of online learning platform</div>
        </div>
        <!-- <div class="yh-lh40 login-nav-right">{{info.title}}：{{info.value}}</div> -->
      </div>
    </div>
    <div class="login-main">
      <div class="login-con">
        <img src="../assets/image/login-left.png" alt="" class="login-left">
        <div class="login-con-right">
          <ul class="login-con-head">
            <li @click="changeType(index)" :class="{ active: ruleForm.type == index }" v-for="(item, index) in 2"
              :key="index">{{ index == 0 ? '学员登录'
                :
                '注册' }}</li>
          </ul>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
            <template v-if="ruleForm.type == 0">
              <el-form-item prop="account">
                <el-input v-model="ruleForm.account" placeholder="请输入手机号或身份证号"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input type="password" v-model="ruleForm.password" placeholder="请输入密码"></el-input>
              </el-form-item>
            </template>
            <div v-else>
              <el-form-item prop="realname">
                <el-input v-model="ruleForm.realname" placeholder="请输入姓名"></el-input>
              </el-form-item>
              <el-form-item prop="mobile">
                <el-input maxlength="11" v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input v-model="ruleForm.code" placeholder="请输入验证码">
                  <template slot="append">
                    <el-button @click="getCode">{{ codeText }}</el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input type="password" v-model="ruleForm.password" placeholder="设置密码：8到18位包含数字和字母"></el-input>
              </el-form-item>
              <el-form-item prop="password1">
                <el-input type="password" v-model="ruleForm.password1" placeholder="请再次输入密码"></el-input>
              </el-form-item>
            </div>
            <el-form-item>
              <el-button class="button" type="primary" @click="submitForm('ruleForm')">{{ ruleForm.type == 0 ? '立即登录' :
                '注册' }}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $DB from "@/utils/debounce";
export default {
  data() {
    return {
      ruleForm: {
        type: 0,
        account: "", //手机号
        password: "", //密码
        password1: "",
        realname: "",
        idcardnumber: "",
        mobile: "",
        code: "",
      },
      info:{},//
      codeText: '获取验证码',
      time: 0, // 倒计时
      timer: null, // 倒计时定时器
      isCilik: false,
      rules: {
        account: [
          { required: true, message: '请输入手机号或身份证号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        password1: [
          { required: true, message: '请再次输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm: $DB(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(this.$api[this.ruleForm.type == 0 ? 'accountLogin' : 'register'], this.ruleForm).then(res => {
            console.log(res)
            if (res.code === 1) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              if (this.ruleForm.type == 0) {
                sessionStorage.setItem("token", res.data.token);
                this.$store.commit("upIsLogin", true);
                this.$router.push({ path: '/my/mycourses' })
                this.$axios.get(this.$api.myinfo).then((ress) => {
                  if (ress.code === 1) {
                    this.$store.commit("upUserInfo", ress.data);
                  }
                });
              } else {
                this.ruleForm.type = 1
              }
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }),
    // 获取验证码
    getCode: $DB(function () {
      if (this.isCilik) return;
      if (!this.ruleForm.mobile)
        return this.$message({
          message: "请填写手机号",
          type: "warning",
        });
      if (!this.$regexPhone.test(this.ruleForm.mobile))
        return this.$message({
          message: "请检查手机号",
          type: "warning",
        });

      this.isCilik = true;
      this.$axios
        .post(this.$api.sendSms, {
          event: "register",
          mobile: this.ruleForm.mobile
        })
        .then((res) => {
          if (res.code == 1) {
            this.time = 59;
            this.timer = setInterval(() => {
              this.time--;
              this.codeText = this.time + 'S'
              if (this.time <= 0) {
                this.time = 60
                this.isCilik = false;
                this.codeText = '获取验证码'
                clearInterval(this.timer)
              }
            }, 1000);
            this.$message({
              message: res.msg,
              type: "success",
            });
          }
        });
    }),
    //切换
    changeType(type) {
      this.ruleForm.type = type
      this.ruleForm.password = ''
    },
    getInfo () {
      this.$axios.post("/api/common/serviceHotline").then((res) => {
                if (res.code == 1) {
                    this.info = res.data;
                }
            });
    }
  },
  created () {
    this.getInfo()
  }
}
</script>
<style lang="scss">
.login {
  height: 100vh;
  background: #F1F3F7;
}

.login-nav {
  height: 210px;
  background: url('../assets/image/toubu1-bj.png') no-repeat;
  background-size: cover;

  .login-nav-main {
    width: 1200px;
    display: flex;
    height: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
  }

  .login-nav-left {
    div {
      &:first-child {
        color: #1263ff;
        font-size: 36px;
        font-weight: bold;
        font-family: Microsoft YaHei;
      }

      &:last-child {
        color: #333333;
        font-size: 15.5px;
      }
    }
  }

  .login-nav-right {
    color: #1263ff;
    font-size: 20px;
    font-weight: bold;
    user-select: text;
  }
}

.login-main {
  margin-top: -30px;
  .login-con {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    border-radius: 20px;
    background: #FFFFFF;

    .login-left {
      width: 600px;
      height: 720px;
    }

    .login-con-right {
      flex: 1;
      padding: 120px 0 0 0;
      display: flex;
      // justify-content: center;
      align-items: center;
      flex-direction: column;

      .login-con-head {
        display: flex;

        li {
          cursor: pointer;
          color: #666666;
          font-size: 24px;

          &:first-child {
            margin-right: 120px;
          }
        }

        .active {
          color: #1263FF;
          position: relative;

          &::after {
            left: 50%;
            content: '';
            bottom: -10px;
            transform: translateX(-50%);
            position: absolute;
            width: 32px;
            height: 2px;
            background: #1263FF;
          }
        }
      }

      .demo-ruleForm {
        width: 420px;
        margin-top: 40px;

        .el-form-item__content {
          line-height: 50px;
        }

        .el-input__inner {
          line-height: 50px;
          height: 50px;
        }

        .button {
          width: 420px;
          height: 48px;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 48px;
          padding: 0;
          margin: 0 auto;
          background: #1263FF;
          border-radius: 4px;
        }
      }
    }
  }
}</style>